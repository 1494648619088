import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import Section from '~components/Section'
import Tile from '~components/Tile'
import { mobile } from '~styles/global'
import { AnimatePresence, motion } from 'framer-motion'
import randomise from '~utils/randomise'
import ProductionTile from '~components/ProductionTile'

const IndexPage = ({ data }) => {
	
	const { page } = data
	const [filter, setFilter] = useState(undefined)
	const [displayedPosts, setDisplayedPosts] = useState([])

	const allPosts = data?.graduates?.nodes ? data?.graduates?.nodes : data?.productions?.nodes

	const graduates = data?.graduates?.nodes

	useEffect(() => {
		setFilter('a-z')
	}, [])

	useEffect(() => {
		if (filter === 'a-z'){
			setDisplayedPosts(allPosts)
		} else if (graduates){
			let filteredPosts = allPosts?.filter(p => {
				if (p.course?.some(c => c.slug?.current === filter)){
					return true
				}
				if (p.tags?.some(c => c.slug?.current === filter)){
					return true
				}
			})
			setDisplayedPosts(filteredPosts)
		} else {
			let filteredPosts = allPosts?.filter(p => (p.tags?.some(c => c.slug?.current === filter)))
			setDisplayedPosts(filteredPosts)
		}
	}, [filter])

	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<DataViewer data={data} name="data"/>
			<Header>
				<Heading color={page.keyColour}>
					{page.title}
				</Heading>
			</Header>
			<Section>
				<Filters>
					<AZButton 
						className='h4'
						onClick={() => setFilter('a-z')}
						active={filter === 'a-z'}
					>
						<FBText>
							A-Z
						</FBText>
					</AZButton>
					{page?.tagMenu?.map(tag => (
						<FilterButton 
							className='h4' 
							key={tag?.slug?.current}
							onClick={() => setFilter(tag?.slug?.current)}
							active={filter === tag?.slug?.current}
						>
							<FBText>
								{tag.title}
							</FBText>
						</FilterButton>
					))}
				</Filters>
			</Section>
			<PostsHeight>
				<AnimatePresence exitBeforeEnter>
					<Fade 
						key={displayedPosts} 
						transition={{ duration: 0.6 }} 					
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<Posts>
							{displayedPosts?.length > 0 ? 
								displayedPosts?.map((p, i) => (
									p._type === 'graduate' ?
										<Tile g={p} key={p.slug?.current} index={randomise(i)} color={p.course[0]?.keyColour}/>
										:
										<ProductionTile p={p} key={p.slug?.current} index={randomise(i)}/>
								))
								:
								<NoPosts>
									No posts to show
								</NoPosts>
							}
						</Posts>
					</Fade>
				</AnimatePresence>
			</PostsHeight>
		</>
	)
}

const Header = styled(Section)`
	margin-bottom: 90px;
`
const Heading = styled.h1`
	color: ${ props => props.color };
	grid-column: span 12;
`
const Filters = styled.div`
	grid-column: span 12;
	margin-bottom: 52px;
	${mobile}{
		margin-bottom: 6px;
	}
`
const FilterButton = styled.button`
	text-transform: uppercase;
	border: 2px solid;
	padding: 0 46px;
	border-radius: 50px;
	height: 54px;
	margin-right: 26px;
	margin-bottom: 17px;
	opacity: ${ props => props.active ? '1' : '0.5'};
	transition: opacity 0.25s;
	:hover{
		opacity: 1;
	}
	${mobile}{
		height: 30px;
		margin-right: 10px;
		font-family: 'Roboto Condensed', sans-serif;
		padding: 0 14px 1px;
		font-size: 13px;
		max-width: calc(100vw - 40px);
		> div {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
`
const AZButton = styled(FilterButton)`
	padding: 0 24px;	
	${mobile}{
		padding: 0 14px;
	}
`
const FBText = styled.div`
	position: relative;
	top: 0.1em;
`
const Fade = styled(motion.div)`
`
const PostsHeight = styled.div`
	min-height: 40vh;
`
const Posts = styled(Section)`
	> div {
		grid-column-gap: 0px;
		${mobile}{
			grid-column-gap: 0px;
		}
	}
`
const NoPosts = styled.div`
	grid-column: span 12;
`

IndexPage.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query IndexPageQuery($slug: String, $isGraduates: Boolean!) {
    page: sanityIndexPage(slug: {current: {eq: $slug}}) {
			title
			tagMenu {
				title
				slug {
					current
				}
			}
    }
		graduates: allSanityGraduate(sort: {fields: title, order: ASC}) @include(if: $isGraduates) {
			nodes {
				...graduateThumb
			}
		}
		productions: allSanityProduction(sort: {fields: title, order: ASC}) @skip(if: $isGraduates) {
			nodes {
				...productionThumb
			}
		}
  }
`

export default IndexPage